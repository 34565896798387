@font-face {
    font-family: 'HLN-Bold';
    src: url("../assets/fonts/HLN-Bold.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HLN-Medium';
    src: url("../assets/fonts/HLN-Medium.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HLN-Italic';
    src: url("../assets/fonts/HLN-SemiboldItalic.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HLN-Book';
    src: url("../assets/fonts/HLN-Book.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
}

input[type="password"] {
    font: small-caption;
    font-size: 14px
}

h2 {
    color: #ffffff !important;
}